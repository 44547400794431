<!-- Documentation link: https://kit.svelte.dev/docs/routing#page-page-svelte -->

<script>
  import { _ } from "svelte-i18n";
  import { pushState } from "$app/navigation";
  import Search from "$lib/Search.svelte";
  import Streamers from "$lib/Streamers.svelte";
  import Filters from "$lib/Filters.svelte";
  import MovieList from "$lib/MovieList.svelte";
  import MovieDetail from "$lib/MovieDetail.svelte";
  import { settings_store } from "$lib/store.js";
  import { onMount } from "svelte";

  export let data;
  let titleID;

  const randomKey = `sample_prompt${Math.trunc(Math.random() * 70) + 1}`;
  $: prompt = $_(randomKey);

  function closeDetailModal() {
    removeTitleIDfromURL();
    document.body.style.overflow = "";
  }

  function removeTitleIDfromURL() {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete("id");
    currentUrl.searchParams.delete("title");
    pushState(currentUrl.pathname + currentUrl.search);
    titleID = false;
  }

  onMount(() => {
    const currentUrl = new URL(window.location.href);
    titleID = currentUrl.searchParams.get("id") || false;
  });
</script>

<!-- ---------------------------------------------------------------------- -->

{#if !prompt.startsWith("sample_") && $settings_store}
  {#if titleID}
    <MovieDetail {titleID} isOpen={true} singleMode={true} on:close={closeDetailModal} />
  {/if}
  <div id="search">
    <Search samplePrompt={prompt} cache={data} />
  </div>
  <Streamers />
  <Filters />
  <MovieList />
{/if}

<!-- ---------------------------------------------------------------------- -->

<style>
  #search {
    /* position: sticky; */
    top: var(--header-height);
    z-index: 100;
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, var(--background) 88%, rgba(0, 0, 0, 0) 100%);
    scroll-snap-align: start;
    transition: top 0.25s ease-in-out;
  }
</style>
