<script>
  let movie;
  let backdropImageUrl;
  let movieTrailerUrl;
  export let titleID;
  export let index = 0;
  export let isOpen = false;
  export let singleMode = false;

  import { afterUpdate, onMount } from "svelte";
  import { _, locale } from "svelte-i18n";
  import Availability from "$lib/Availability.svelte";
  import { createEventDispatcher } from "svelte";
  import MoviePlayer from "$lib/MoviePlayer.svelte";
  import FlyingConfirmation from "$lib/FlyingConfirmation.svelte";
  import {
    recommended_titles,
    settings_store,
    recommended_titles_index,
    all_posters_loaded,
    startup_state
  } from "$lib/store";
  import Keyword from "./Keyword.svelte";
  import { locale_options } from "$lib/LocaleConstants.js";
  import { PUBLIC_API_BASE_URL } from "$env/static/public";
  import { goto } from "$app/navigation";

  let showContent = false;
  let showCategories = false;
  let keywordGroups = {};
  let synopsisID;
  let showSkipLeftButon;
  let showSkipRightButton;
  let confirmation;
  $: googlePeekMode = index < 8 && $all_posters_loaded && $startup_state;

  $: if (isOpen && !movie && titleID) {
    defineMovie(titleID);
  }

  $: if (isOpen && movie) {
    groupKeywords();
    showContent = true;
    document.body.style.overflow = "hidden";
  } else {
    showContent = false;
  }

  $: if ($recommended_titles_index > 0) {
    showSkipLeftButon = true;
  } else {
    showSkipLeftButon = false;
  }

  $: if ($recommended_titles_index < $recommended_titles.length - 1) {
    showSkipRightButton = true;
  } else {
    showSkipRightButton = false;
  }

  // automatically retrieving movie.getDetails:
  all_posters_loaded.subscribe((action) => {
    if (action && index < 8 && $startup_state) {
      setTimeout(() => {
        if (!movie) {
          defineMovie(titleID);
        }
      }, 250 * index);
    }
  });

  async function defineMovie(titleID) {
    const details = await getDetails(titleID);
    if (details !== null) {
      movie = details;
      backdropImageUrl = getBackdropImageUrl(movie);
      let trailerInfo = details.videos.find((video) => video.type === "TRAILER" || video.type === "CLIP");
      movieTrailerUrl = trailerInfo ? trailerInfo.url : "";
    }
  }

  async function getDetails(movieId) {
    const endpoint = "/api/info/getMovieDetail";
    const encodedId = encodeURIComponent(movieId);
    const params = `/${encodedId}?region=${$settings_store.region}&locale=${locale_options[$locale]}`;
    const url = PUBLIC_API_BASE_URL + endpoint + params;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch details");
      }
      const response_json = await response.json();
      return response_json.data.title;
    } catch (err) {
      console.error(err.message);
      goto("/error");
    }
  }

  function getBackdropImageUrl() {
    let backdrop = movie.image_urls.find((i) => i.image_type === "BACKDROP");
    if (backdrop === null || backdrop === undefined || backdrop === "") {
      return "/no_backdrop.svg";
    } else {
      return backdrop.image_url;
    }
  }

  function groupKeywords() {
    if (movie.tags) {
      let keywords = [...movie.tags];
      keywords.forEach((keyword) => {
        if (!keywordGroups[keyword.category]) {
          keywordGroups[keyword.category] = [];
        }
        keywordGroups[keyword.category].push(keyword);
      });
      // sort groups
      if (keywordGroups["Mood"]) {
        const mood = { Mood: keywordGroups["Mood"] }; // Extract category
        delete keywordGroups["Mood"]; // Remove category from original position
        keywordGroups = { ...mood, ...keywordGroups }; // Add category at the end
      }
      if (keywordGroups["null"]) {
        const null1 = { null: keywordGroups["null"] }; // Extract category
        delete keywordGroups["null"]; // Remove category from original position
        keywordGroups = { ...keywordGroups, ...null1 }; // Add category at the end
      }
      // if (keywordGroups["Title"]) {
      //   delete keywordGroups["Title"]; // Remove category from original position
      // }
    }
  }

  function skipToNextMovie() {
    if ($recommended_titles_index < $recommended_titles.length - 1) {
      dispatch("close");
      recommended_titles_index.set($recommended_titles_index + 1);
      isOpen = false;
    }
  }

  function skipToPreviousMovie() {
    if ($recommended_titles_index > 0) {
      dispatch("close");
      recommended_titles_index.set($recommended_titles_index - 1);
      isOpen = false;
    } else {
      recommended_titles_index.set(0);
    }
  }

  const dispatch = createEventDispatcher();

  function closeModal() {
    recommended_titles_index.set(-1);
    isOpen = false;
    document.body.style.overflow = "";
    dispatch("close");
  }

  let text_is_ellipsed = false;
  function detectElipsis() {
    if (!showContent || !movie) {
      return;
    } else if (synopsisID) {
      if (synopsisID.scrollHeight > synopsisID.clientHeight || is_expanded) {
        text_is_ellipsed = true;
      } else {
        text_is_ellipsed = false;
      }
    }
  }

  let is_expanded = false;
  function toggleSynopsis() {
    is_expanded = !is_expanded;
  }

  async function copyText(textToCopy, message) {
    try {
      await navigator.clipboard.writeText(textToCopy);
      confirmation = message;
    } catch (err) {
      console.error("Failed to copy to clipboard: ", err);
    }
  }

  afterUpdate(() => {
    detectElipsis();
    if (window.innerWidth <= 480) {
      var hidingLayerComponent = document.getElementById("movieDetail");
      if (hidingLayerComponent) {
        hidingLayerComponent.style.background = "rgba(0, 0, 0, 1.8)";
      }
    }
  });

  onMount(() => {
    const params = new URLSearchParams(window.location.search);
    showCategories = params.get("preview") === "true";
    if (singleMode) {
      window._paq.push(["trackEvent", "movie", "direct-open"]);
    }
    window.addEventListener("resize", detectElipsis);
    return () => {
      window.removeEventListener("resize", detectElipsis);
    };
  });
</script>

<!-- ---------------------------------------------------------------------- -->

{#if isOpen || googlePeekMode}
  <div
    class="movie-detail"
    style={googlePeekMode && !isOpen ? "height: 0; padding: 0" : ""}
    id="movieDetail"
    on:click={closeModal}
    role="none">
    {#if !movie}
      <div class="loader" />
    {/if}
    {#if movie}
      <div class="modal-content" role="none" on:click|stopPropagation>
        <div>
          <button
            class="copy-link-button"
            title="copy link"
            on:mousedown={() => copyText(window.location.href, $_("copied link to clipboard"))} />
          <button class="close-button" on:click={closeModal} />
        </div>
        {#if showContent && movie}
          <MoviePlayer {backdropImageUrl} {movieTrailerUrl} {movie} {isOpen} />
          <div class="modal-body">
            <div class="movie-header">{movie.name}</div>
            {#if movie.name != movie.original_name}
              <div class="movie-header-orig">
                <span class="info-label">{$_("original_title")}:</span>
                {movie.original_name}
              </div>
            {/if}
            <div class="detail-movie">
              <div>
                {#if movie.release_year}
                  <span class="info">{movie.release_year}</span>
                {/if}
                {#if movie.production_countries.length > 0}
                  <span class="info">
                    {movie.production_countries.join(", ")}
                  </span>
                {/if}
                {#if movie.genres.length > 0}
                  <span class="info">{movie.genres.join(", ")}</span>
                {/if}
                {#if movie.duration}
                  <span class="info">{movie.duration} min</span>
                {/if}
              </div>
              <div>
                {#if movie.directors.length > 0}
                  <span class="info-label">{$_("by-director")}:</span>
                  <span class="info">{movie.directors.join(", ")}</span>
                {/if}
                {#if movie.cast.length > 0}
                  <span class="info-label">{$_("with-cast")}:</span>
                  <span class="info">
                    {movie.cast.slice(0, 5).join(", ")}
                  </span>
                {/if}
              </div>
            </div>

            <div>
              <p
                class="synopsis {is_expanded ? 'expanded' : ''}"
                id="synopsis"
                bind:this={synopsisID}
                title={movie.synopsis}>
                {movie.synopsis}
              </p>
              <button
                id="expandButton"
                on:click={toggleSynopsis}
                style="visibility: {text_is_ellipsed ? 'visible' : 'hidden'}"
                class="synopsis-button {is_expanded ? 'collapse' : 'expand'}" />
            </div>

            <div class="streaming-links">
              {#if movie.streaming_availabilities.filter( (a) => ["RENT", "BUY", "FREE"].includes(a.monetization_type) ).length > 0}
                <div class="type-segment">
                  <div class="streamers-segments-header">
                    {$_("streamer-category-rent")} / {$_("streamer-category-buy")}
                  </div>
                  {#each movie.streaming_availabilities.filter( (a) => ["RENT", "BUY", "FREE"].includes(a.monetization_type) ) as availability}
                    <Availability {availability} {movie} />
                  {/each}
                </div>
              {/if}
              {#if movie.streaming_availabilities.filter((a) => a.monetization_type === "FLATRATE").length > 0}
                <div class="type-segment">
                  <div class="streamers-segments-header">
                    {$_("streamer-category-subscription")}
                  </div>
                  {#each movie.streaming_availabilities.filter((a) => a.monetization_type === "FLATRATE") as availability}
                    <Availability {availability} {movie} />
                  {/each}
                </div>
              {/if}
            </div>
            {#if showCategories}
              <div id="keyword-groups">
                {#each Object.entries(keywordGroups) as [category, keywords]}
                  <section class="keyword-category">
                    <div class="category-header">
                      <div
                        class="category-icon"
                        style="background-image: url('/icons/icon_{category.toLowerCase()}.svg" />
                      <h2>{$_(category)}</h2>
                    </div>
                    <div class="keywords">
                      {#each keywords as keyword}
                        <Keyword {keyword} showChevron={true} />
                      {/each}
                    </div>
                  </section>
                {/each}
              </div>
            {/if}
            {#if showSkipLeftButon && !singleMode}
              <button class="skip-left" on:click={skipToPreviousMovie} />
            {/if}
            {#if showSkipRightButton && !singleMode}
              <button class="skip-right" on:click={skipToNextMovie} />
            {/if}
            <div class="technical-info">
              <button
                title={$_("copy_to_clipboard")}
                on:mousedown={() => copyText(movie.id, "copied id to clipboard")} />
              <span>mowizz ID: {movie.id}</span>
            </div>
          </div>
        {/if}
        {#if confirmation}
          <FlyingConfirmation {confirmation} on:close={() => (confirmation = false)} />
        {/if}
      </div>
    {/if}
  </div>
{/if}

<!-- ---------------------------------------------------------------------- -->

<style>
  .movie-detail {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--true-black-80);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 2%;
    z-index: 1000;
  }
  .modal-content {
    display: inline;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    width: 70%;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background: var(--true-black);
    line-height: 1.4;
    transition: width 0.3s;
  }
  .close-button {
    background: url(/buttons/btn_close_details.svg) no-repeat;
    width: 48px;
    height: 48px;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 1010;
  }
  .close-button:hover {
    background: url(/buttons/btn_close_details_hover.svg) no-repeat;
  }
  .copy-link-button {
    background: url(/buttons/btn_copy_link.svg) no-repeat;
    width: 48px;
    height: 48px;
    border: none;
    position: absolute;
    top: 10px;
    right: 64px;
    cursor: pointer;
    z-index: 1010;
  }
  .copy-link-button:hover {
    background: url(/buttons/btn_copy_link_hover.svg) no-repeat;
  }
  .modal-body {
    margin-top: -6rem;
    margin-left: 60px;
    margin-right: 60px;
    padding-bottom: 10px;
    position: relative;
    margin-left: 60px;
    margin-right: 60px;
  }
  .movie-header {
    color: var(--white);
    font-size: 40px;
    font-weight: 300;
    line-height: 120%;
  }
  .movie-header-orig {
    color: var(--light-grey);
  }
  .detail-movie {
    color: var(--light-grey);
    margin-top: 1em;
  }
  .info:not(:last-child)::after {
    content: "•";
    white-space: pre;
    margin: 0 0.2em 0 0.5em;
  }
  .info-label {
    margin-right: 0.3em;
  }
  .synopsis {
    color: var(--white-grey);
    margin-top: 20px;
    margin-bottom: 0px;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 300;
    transition: all 0.25s;
  }
  .synopsis.expanded {
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
    overflow: unset;
  }
  .synopsis-button {
    width: 36px;
    height: 36px;
    background-color: var(--solid-grey);
    border: solid 1px transparent;
    border-radius: 18px;
    cursor: pointer;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .synopsis-button.expand {
    background: url(/icons/icon_dots.svg) no-repeat var(--dark-grey);
    background-size: 24px;
    background-position: 50%;
  }
  .synopsis-button.expand:hover {
    /* background: url(/buttons/btn_down_hover.svg) no-repeat; */
    /* background-size: contain; */
    border: solid 1px var(--white);
  }
  .synopsis-button.collapse {
    background: url(/icons/icon_chevron.svg) no-repeat var(--dark-grey);
    background-size: 24px;
    transform: rotate(-180deg);
    background-position: 50%;
  }
  .synopsis-button.collapse:hover {
    /* background: url(/buttons/btn_up_hover.svg) no-repeat;
    background-size: contain; */
    border: solid 1px var(--white);
  }
  h2 {
    font-size: 1.25rem;
    color: var(--lightest-blue);
    margin-bottom: 0;
  }
  #keyword-groups {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 8px;
    margin-bottom: 100px;
  }
  .keyword-category {
    display: block;
  }
  .category-header {
    display: flex;
  }
  .category-icon {
    filter: brightness(0) saturate(100%) invert(75%) sepia(63%) saturate(1862%) hue-rotate(172deg) brightness(100%)
      contrast(101%);
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    margin-block-start: 1.15em;
  }
  .keywords {
    display: flex;
    flex-flow: row wrap;
    max-height: 158px;
    overflow: hidden;
  }
  .skip-left {
    background-image: url("/buttons/btn_left.svg");
    background-color: transparent;
    position: absolute;
    margin-left: -60px;
    top: 20px;
    left: -8px;
    height: 48px;
    width: 48px;
    cursor: pointer;
    border: none;
    opacity: 70%;
  }
  .skip-left:hover {
    background-image: url("/buttons/btn_left_hover.svg");
    opacity: 100%;
  }
  .skip-right {
    background-image: url("/buttons/btn_right.svg");
    background-color: transparent;
    position: absolute;
    margin-right: -60px;
    top: 20px;
    right: -8px;
    height: 48px;
    width: 48px;
    cursor: pointer;
    border: none;
    opacity: 70%;
  }
  .skip-right:hover {
    background-image: url("/buttons/btn_right_hover.svg");
    opacity: 100%;
  }
  .loader {
    background-image: url("/icons/icon_bouncing-circles.svg");
    width: 50px;
    height: 52px;
    align-self: center;
    margin: 0 0 0 0;
  }

  @media (max-width: 1000px) {
    .modal-content {
      width: 88%;
    }
    .modal-body {
      margin-left: 40px;
      margin-right: 40px;
    }
    .skip-left,
    .skip-right {
      top: 20px;
      margin-left: -40px;
      margin-right: -40px;
    }
  }

  @media (max-width: 640px) {
    .modal-content {
      width: 100%;
    }
    .movie-header {
      font-size: 28px;
    }
    .modal-body {
      margin-top: -3rem;
      margin-left: 24px;
      margin-right: 24px;
    }
    .skip-left,
    .skip-right {
      top: 20px;
      margin-left: -28px;
      margin-right: -28px;
    }
  }

  /* ---------streaming links:--------------------- */
  .streaming-links {
    color: var(--white);
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .type-segment {
    flex: 1 1 0;
    min-width: 275px;
    box-sizing: border-box;
    background-color: var(--dark-grey);
    block-size: -webkit-fit-content;
    block-size: -moz-fit-content;
    block-size: fit-content;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin: 4px;
    padding: 8px 8px 8px;
    position: relative;
  }
  .streamers-segments-header {
    color: var(--light-blue);
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 4px;
    margin-left: 10px;
  }
  .technical-info {
    margin-top: 1em;
    margin-bottom: 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .technical-info span {
    margin-left: 1em;
    color: var(--white-00);
  }
  .technical-info button {
    background: url(/buttons/btn_copy.svg) no-repeat;
    width: 20px;
    height: 20px;
    border: none;
    position: relative;
    margin-left: 1em;
    cursor: pointer;
  }
</style>
