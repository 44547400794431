<script>
  import { createEventDispatcher } from "svelte";

  export let confirmation = false;
  export let zIndex = 1020;
  //   export let showConfirmation;
  let mouseX;
  let mouseY;
  const dispatch = createEventDispatcher();

  $: if (confirmation) {
    window.addEventListener("mouseup", updateMousePosition);
    setTimeout(() => {
      confirmation = false;
      window.removeEventListener("mouseup", updateMousePosition);
      dispatch("close");
    }, 1500);
  }

  const updateMousePosition = (event) => {
    mouseX = event.clientX;
    mouseY = event.clientY;
  };
</script>

<!-- ---------------------------------------------------------------------- -->

<div class="confirmation-label" style="left: {mouseX}px; top: {mouseY}px; z-index: {zIndex}">
  {confirmation}
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .confirmation-label {
    position: fixed;
    left: 50%;
    top: 50%;
    /* width: 100px; */
    /* transform: translate(-100%, -100%); */
    font-size: 1rem;
    text-align: center;
    padding: 1rem;
    color: var(--black);
    background-color: var(--white);
    border-radius: 0.75rem;
    pointer-events: none;
    opacity: 0; /* Start with fully hidden */
    animation: flyAndFade 1.5s ease-in-out forwards;
  }

  @keyframes flyAndFade {
    0% {
      transform: translate(-50%, 50%) scale(0.8);
      opacity: 0;
    }
    50% {
      transform: translate(-50%, 60%) scale(1.2); /* Float up slightly */
      opacity: 1; /* Fully visible */
    }
    100% {
      transform: translate(-50%, 50%) scale(1);
      opacity: 0; /* Fade out */
    }
  }
</style>
